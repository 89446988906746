import { StyleSheet,Dimensions } from 'react-native';
import { colors } from '../../utils/colors';

// import Constants from 'expo-constants';

const screen = Dimensions.get('screen');

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'left',
        justifyContent: 'left',
        paddingHorizontal: 20,
      },
      header: {
        flexDirection: 'row' 
      },
      logo: {
        width: 50,
        height: 50,
        marginRight: 10,
      },
      logoName: {
        // width: '100%',
        width: 200,
        height: 50, 
        // aspectRatio: 1,
        resizeMode: 'contain'
      },
      title: {
        fontSize: 30,
        fontWeight: 'bold',
        paddingVertical: 20,
      },
      sectionHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingVertical: 20,
      },
      jobText: {
        paddingBottom: 6,
        textDecorationLine: 'underline',
        color: colors.hmGreen,

      },
      image: { 
        width: 200, 
        height: 200,
        // height: '100%'
        resizeMode: 'contain'
      },



      closeButton: {
        color: 'white',
        borderWidth: 1,
        borderColor: 'white',
        padding: 8,
        borderRadius: 3,
        textAlign: 'center',
        margin: 10,
        alignSelf: 'flex-end',
      },
      customHeaderBox: {
        height: 150,
        backgroundColor: '#6C7A89',
        justifyContent: 'center',
        alignItems: 'center',
      },
      
      col: {
        flex: 1,
      },
      
      squareFirst: {
        backgroundColor: '#C0392B',
      },
      squareSecond: {
        backgroundColor: '#019875',
      },
      squareText: {
        textAlign: 'center',
        color: 'white',
      },
      
      contain: {
        flex: 1,
        height: 150,
      },
     
      customItemBox: {
        height: 150,
        justifyContent: 'center',
        alignItems: 'center',
      },
      customItemButton: {
        backgroundColor: '#000000',
        justifyContent: 'center',
        alignItems: 'center',
      },
      customItemText: {
        padding: 20,
        color: '#FFFFFF'
      }
});

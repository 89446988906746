import React, { useEffect, useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Text, View} from 'react-native';
import { styles } from './styles';

export default function Job({ navigation, route }) {

  console.log("route: ", route)

  return (
    <View style={styles.container}>
        <Text>Not Found</Text>
      <StatusBar style="auto" />
    </View>
  );
}
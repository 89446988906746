import React, { useEffect, useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { styles } from './styles';

/**
 * This Component will show a circular loading 
 * indicator in the center of the screen while the servises.js 
 * functions are fetching the data from TMDB.
*/

const Loading = () => {
  return (
    <View style={styles.container}>
      <ActivityIndicator size="small" color="#000000" />
      <Text style={styles.text}>
        Loading...
      </Text>
    </View>
  );
};

export default Loading;
import { StyleSheet } from 'react-native';
import { colors } from '../../utils/colors';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: colors.white,
    },
    text: {
        color: 'black', 
        justifyContent: 'center',
        alignSelf: 'center',
        marginTop: 10
    }
});
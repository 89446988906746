import axios from 'axios';

//const URL = process.env.EXPO_PUBLIC_HOUSEMINT_API_URL;
const URL = "https://project-housemint.wl.r.appspot.com";
//const URL = "http://localhost:5001"
//const URL = "https://api.housemint.io"

/**
 * fetchMovies takes one parameter, "search".
 * "search" is a search term which we will get from the TextInput 
 * component of HomeScreen.js screen, if the "search" is empty 
 * then we will fetch the list of movies from the movie/popular route of 
 * TMDB API which will give us a list of current popular movies,
 * and if the search term is not empty, we will fetch the data of
 * searched movie.
*/



export const fetchJob = async (userId, projectId, jobId) => {
  //console.log('fetchJob');
  //console.log('URL: ', URL);
  const response = await axios.get(`${URL}/housemint/api/v1/user/${userId}/project/${projectId}/job/${jobId}`);
  console.log('fetchJob() response: ', response);
  return response.data;
};

export const fetchProject = async (userId, projectId) => {
  //console.log('fetchJob');
  //console.log('URL: ', URL);
  const response = await axios.get(`${URL}/housemint/api/v1/user/${userId}/project/${projectId}`);
  console.log('fetchProject() response: ', response);
  return response.data;
};

export const fetchPublishedProjects = async () => {
  //console.log('fetchJob');
  //console.log('URL: ', URL);
  const response = await axios.get(`${URL}/housemint/api/v1/projects`);
  console.log('fetchPublishedProjects() response: ', response);
  return response.data;
};

export const fetchJobsForProject = async (userId, projectId) => {
  const response = await axios.get(`${URL}/housemint/api/v1/user/${userId}/project/${projectId}/job`);
  console.log('fetchJobsForProject() response: ', response);
  return [...response.data];
};


export const fetchMediaForProject = async (userId, projectId) => {
  const response = await axios.get(`${URL}/housemint/api/v1/user/${userId}/project/${projectId}/media/list`);
  console.log('fetchMediaForProject() response: ', response);
  return [...response.data];
};

// export const fetchMovies = async (search) => {
//   console.log('fetch movies', search);
//   if (!search) {
//     const response = await axios.get(`${URL}movie/popular?api_key=${API_KEY}`);
//     return [...response.data.results];
//   } else {
//     console.log('in else');
//     const response = await axios.get(
//       `${URL}search/movie?api_key=${API_KEY}&language=en-US&query=${search}`
//     );
//     return [...response.data.results];
//   }
// };

// export const fetchNewMoviesUnsordted = async () => {
//   console.log('fetchNewMovies');
//   const response = await axios.get(`${URL}discover/movie?primary_release_date.gte=2023-09-22&primary_release_date.lte=2023-10-22&include_adult=false&region=US&with_original_language=en&api_key=${API_KEY}`);
//   return [...response.data.results];
// };

// export const fetchNewMovies = async () => {
//   console.log('fetchNewMovies');
//   const response = await axios.get(`${URL}discover/movie?primary_release_date.gte=2023-09-22&primary_release_date.lte=2023-10-22&include_adult=false&region=US&with_original_language=en&api_key=${API_KEY}`);
//   return [...response.data.results];
// };

// export const fetchPopularTVShows = async () => {
//   console.log('fetchPopularTVShows');
//   //https://api.themoviedb.org/3/discover/tv?api_key=3637d8e30c09f3460cc2fe71fe06451d&watch_region=US&with_original_language=en&include_adult=false
//   const response = await axios.get(`${URL}discover/tv?watch_region=US&with_original_language=en&include_adult=false&api_key=${API_KEY}`);
//   return [...response.data.results];
// };

// export const fetchNewTVEpisodes= async () => {
//   console.log('fetchNewTVEpisodes');
//   //https://api.themoviedb.org/3/discover/tv?api_key=3637d8e30c09f3460cc2fe71fe06451d&watch_region=US&with_original_language=en&include_adult=false
//   const response = await axios.get(`${URL}discover/tv?air_date.gte=2023-10-15&air_date.lte=2023-10-22&include_adult=false&watch_region=US&with_original_language=en&with_type=0%7C2%7C3%7C4%7C6&api_key=${API_KEY}`);
//   return [...response.data.results];
// };

/**
 * we will call this function from our MovieScreen.js screen, 
 * fetchCredits take one parameter "id" which will be used for 
 * fetching cast and crew of the movie.
 * it returns the name of the director and the list of crew and 
 * cast which we will use later in this article
*/
// export const fetchCredits = async (id) => {
//   const response = await axios.get(
//     `${URL}movie/${id}/credits?api_key=${API_KEY}`
//   );
//   console.log(response.data.crew);

//   /**
//    * here we will search the name of director  
//    */
//   const director = response.data.crew.find(
//     (dir) => dir.known_for_department === 'Directing'
//   );
//   const credits = response.data;
//   return { director: director, credits: credits };
// };

import React, { useEffect, useState } from 'react';
import { Text, View, FlatList } from 'react-native';
import { Card } from 'react-native-paper';


const JobDetailsPainting = ({ job, styles }) => {
  return (
    <View>

      <View>
        <Text style={styles.propertyName}>Rooms</Text>
        <FlatList style={{paddingVertical: 10}}
          horizontal
          data={job?.paintingJob?.rooms || []}
          renderItem={({ item, index }) => {
            return (
              <Card style={styles.roomCard} >

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Name: </Text>
                  <Text style={styles.propertyValue}>{item?.name}</Text>
                </View>

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Type: </Text>
                  <Text style={styles.propertyValue}>{item?.type}</Text>
                </View>

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Square Foot: </Text>
                  <Text style={styles.propertyValue}>{item?.squareFoot}</Text>
                </View>

               
                <View style={styles.property}>
                  <Text style={styles.propertyName}>Num Coats: </Text>
                  <Text style={styles.propertyValue}>{item?.numberOfCoats}</Text>
                </View>

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Num Doors: </Text>
                  <Text style={styles.propertyValue}>{item?.numberOfDoors}</Text>
                </View>

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Has Trim?: </Text>
                  <Text style={styles.propertyValue}>{item?.hasTrim ? "Yes" : "No"}</Text>
                </View>

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Materials Needed?: </Text>
                  <Text style={styles.propertyValue}>{item?.materialsNeeded ? "Yes" : "No"}</Text>
                </View>

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Paint Color: </Text>
                  <Text style={styles.propertyValue}>{item?.paintColor}</Text>
                </View>

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Current Condition: </Text>
                  <Text style={styles.propertyValue}>{item?.currentCondition} / 5</Text>
                </View>


                <View style={styles.propertyVertical}>
                  <Text style={styles.propertyName}>Additional Comments: </Text>
                  <Text style={styles.propertyValue}>{item?.additionalComments}</Text>
                </View>

              </Card>

            );
          }}
        />
      </View>

    </View>
  );
};

export default JobDetailsPainting;

import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import Home from './src/screens/Home';
import Job from './src/screens/Job';
import NotFound from './src/screens/NotFound';

const Stack = createStackNavigator();

const linking = {
  screens: {
    Home: "",
    Job: "?jobId=:jobId&userId=:userId&projectId=:projectId",
    NotFound: "*",
  },
};

export default function App() {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen name="Home" component={Home} options={{ title: "Housemint: Projects" }} />
        <Stack.Screen name="Job" component={Job} options={{ title: "Housemint: Job" }} />
        <Stack.Screen name="NotFound" component={NotFound} options={{ title: "Housemint: Not Found" }} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
import { StyleSheet,Dimensions } from 'react-native';
import { colors } from '../../utils/colors';
// import Constants from 'expo-constants';

const screen = Dimensions.get('screen');

export const styles = StyleSheet.create({
    // container: {
    //     flex: 1,
    //     backgroundColor: '#fff',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //   },
    container: {
      //flex: 1,
      backgroundColor: '#fff',
      alignItems: 'left',
      justifyContent: 'left',
      paddingHorizontal: 20,
    },
    header: {
      flexDirection: 'row' 
    },
    logo: {
      width: 50,
      height: 50,
      marginRight: 10,
    },
    logoName: {
      // width: '100%',
      width: 200,
      height: 50, 
      // aspectRatio: 1,
      resizeMode: 'contain'
    },
      property: {
        flexDirection: 'row', 
        paddingBottom: 10
      },
      propertyVertical: {
        flexDirection: 'column' ,
        paddingBottom: 10,
      },
      rfPropertyVertical: {
        flexDirection: 'column' ,
        paddingBottom: 10,
        //justifyContent: 'center',
         //alignSelf: 'center',
         alignItems: 'center',
      },
      propertyName: {
        fontWeight: 'bold',
      },
      propertyValue: {
        
      },
      roomCard: {
        flex: 1,
        width: 200,
        height: '100%',
        margin: 5,
        alignSelf: 'center', 
        padding: 5,
        backgroundColor: colors.lightGrey,
        //overflow: 'hidden',
        // borderWidth: 5,
        //marginVertical: 10
    },
    mediaCard: {
      // flex: 1,
      // width: 200,
      // height: '100%',
      // margin: 5,
      // alignSelf: 'center', 
      // padding: 5,
      // backgroundColor: colors.lightGrey,
      //overflow: 'hidden',
      // borderWidth: 5,
      //marginVertical: 10

      flex: 1,
      width: 100,
      margin: 5,
      alignSelf: 'center', 
  },
  image: { 
    width: 200, 
    height: 200,
    // height: '100%'
    resizeMode: 'contain'
  },
  mediaContainer: {
    flex: 1,
    paddingHorizontal: 5,
  },
  columnContainer: {
    flex: 1,
    padding: 20,
    flexDirection: 'row',
  },
  rfPropertyName: {
    //fontSize: 16,
    color: colors.textGrey,
  },
  rfPropertyValue: {
    fontWeight: 'bold',
    fontSize: 23,
  },
  mapContainer: {
    flex: 1,
    // width: 200,
    // height: 200,
  },

  // Images
  media2Container: {
    flex: 1,
    paddingHorizontal: 5,
    width: 200
  },
  contain: {
    flex: 1,
    height: 150,
    //resizeMode: 'contain'
  },
  image2: { 
    //width: 200, 
    width: '100%', 
    height: 200,
    flex: 1,
    // height: '100%'
    resizeMode: 'contain'
  },
  customItemBox: {
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
  },
  customItemButton: {
    backgroundColor: '#000000',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customItemText: {
    padding: 20,
    color: '#FFFFFF'
  }

});

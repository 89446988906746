import React, { useEffect, useState } from 'react';
import { Text, View, ActivityIndicator, FlatList } from 'react-native';
//import { styles } from '../../screens/Job/styles';


const JobDetailsCleaning = ({ job, styles }) => {
  return (
    <View>
{/* 
      <View style={styles.property}>
        <Text style={styles.propertyName}># bedrooms: </Text>
        <Text style={styles.propertyValue}>{job?.cleaningJob.numBedrooms}</Text>
      </View>

      <View style={styles.property}>
        <Text style={styles.propertyName}># bathrooms: </Text>
        <Text style={styles.propertyValue}>{job?.cleaningJob.numBathrooms}</Text>
      </View> */}

      <View style={[styles.propertyVertical, { marginBottom: 10 }]}>
        <Text style={styles.rfPropertyValue}>Other Rooms</Text>
        <FlatList
          data={job?.cleaningJob.otherRooms}
          renderItem={({ item, index }) => {
            return (
              <Text style={styles.rfPropertyName}>{`\u2022 ${item}`} </Text>
            );
          }}
        />
      </View>

      <View style={[styles.propertyVertical, { marginBottom: 10 }]}>
        <Text style={styles.rfPropertyValue}>Scope of cleaning Work</Text>
        <FlatList
          data={job?.cleaningJob.scopeOfWork}
          renderItem={({ item, index }) => {
            return (
              <Text style={styles.rfPropertyName}>{`\u2022 ${item}`} </Text>
            );
          }}
        />
      </View>


      <View style={[styles.propertyVertical, { marginBottom: 10 }]}>
        <Text style={styles.rfPropertyValue}>Additional Scope</Text>
        <FlatList
          data={job?.cleaningJob.additionalScope}
          renderItem={({ item, index }) => {
            return (
              <Text style={styles.rfPropertyName}>{`\u2022 ${item}`} </Text>
            );
          }}
        />
      </View>


    </View>
  );
};

export default JobDetailsCleaning;
import { StyleSheet,Dimensions } from 'react-native';
// import Constants from 'expo-constants';

const screen = Dimensions.get('screen');

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
      },
});

import React, { useEffect, useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Text, View, Image, SectionList, TouchableOpacity } from 'react-native';
import { fetchPublishedProjects } from "../../services/services";
import { styles } from './styles';
import Loading from '../../components/Loading';
import Lightbox from 'react-native-lightbox-v2';


export default function App({ navigation }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  console.log("App")


  useEffect(() => {
    setLoading(true);

    fetchPublishedProjects().then((data) => {
      //console.log("data: ", data)
      setLoading(false);

      // Data needs to be mapp into a data structure that can be read by SectionList
      const remappedData = data.map(item => {
        if (item.jobs.length > 0) {
          return {
            title: (typeof item.name != 'undefined' && item.name) ? item.name : 'N/A',
            data: item.jobs,
            projectId: item.id,
            userId: item?.user?.id,
          };
        }
      });
      //console.log("remappedData", remappedData);
      // Remove the null Items
      const filteredData = remappedData.filter(item => item != null);
      setData(filteredData)
      //console.log("filteredData", filteredData);
    })


  }, []);

  return loading ? (
    <Loading />
  ) : (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image style={styles.logo} source={require('../../../assets/housemint-logo.jpg')} />
        <Image style={styles.logoName} source={require('../../../assets/housemint-logo-name.jpg')} />
      </View>

      <SectionList
        sections={data}
        renderItem={({ item, section }) => (

          <TouchableOpacity
            onPress={() => {
              navigation.navigate('Job', { job: item, userId: section.userId, projectId: section.projectId, jobId: item.id });
            }}>
            <Text style={styles.jobText}>{item.name}</Text>
          </TouchableOpacity>

        )}
        renderSectionHeader={({ section }) => (
          <Text style={styles.sectionHeader}>{section.title}</Text>
        )}
        keyExtractor={item => item.id}
        stickySectionHeadersEnabled
      />
      <StatusBar style="auto" />

    </View>
  );
}


// <View style={styles.container}>
//   <View>
//     <Text style={styles.title}>Housemint</Text>
//   </View>
//     <Text style={styles.sectionHeader}>Jobs</Text>
//     <FlatList
//       data={jobs}
//       renderItem={({ item, index }) => {
//         return (
//           <TouchableOpacity
//             onPress={() => {
//               navigation.navigate('Job', { job: item, userId: userId, projectId: projectId, jobId: item.id });
//             }}>
//             <Text style={styles.jobText}>{item.name}</Text>
//           </TouchableOpacity>
//         );
//       }}
//     />
//   <StatusBar style="auto" />
// </View>


//<View style={styles.container}>
//    <View>
//      <Text style={styles.title}>Housemint</Text>
//    </View>
//      <Text style={styles.sectionHeader}>Jobs</Text>
//      <FlatList
//            data={projects}
//            renderItem={({ item, index }) => (
//              <View>
//                <Text>Project: {item?.id}</Text>
//                <FlatList
//                  data={item?.jobs}
//                  renderItem={({ item, index }) => <Text>{item.name}</Text>}
//                  keyExtractor={item => item.id}
//                />
//              </View>
//            )}
//            keyExtractor={item => item.id}
//          />
//
//    <StatusBar style="auto" />
//  </View>

// https://stackoverflow.com/questions/48019323/react-native-nested-flatlist

// <View style={styles.container}>
//   <View>
//     <Text style={styles.title}>Housemint</Text>
//   </View>
//   <Text style={styles.sectionHeader}>Jobs</Text>
//   <FlatList
//     data={projects}
//     renderItem={({ item, index }) => (
//       <View>
//         <Text>ProjectId: {item?.id}</Text>
//         <Text>UserId: {item?.user.id}</Text>
//         <FlatList
//           data={item?.jobs}
//           renderItem={({ item, index }) => {
//             return (
//               <TouchableOpacity
//               onPress={() => {
//                 navigation.navigate('Job', { job: item, userId: userId, projectId: projectId, jobId: item.id });
//               }}>
//               <Text>JobId: {item.id}</Text>
//               <Text style={styles.jobText}>{item.name}</Text>
//             </TouchableOpacity>
//             );
//           }}
//           keyExtractor={item => item.id}
//         />
//       </View>
//     )}
//     keyExtractor={item => item.id}
//   />

//   <StatusBar style="auto" />
// </View>
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Text, View, Image, FlatList, TouchableOpacity } from 'react-native';
import { fetchJob, fetchProject, fetchMediaForProject } from "../../services/services";
import { styles } from './styles';
import Loading from '../../components/Loading';
import JobDetailsCleaning from '../../components/JobDetailsCleaning';
import JobDetailsFlooring from '../../components/JobDetailsFlooring';
import JobDetailsPainting from '../../components/JobDetailsPainting';
import { Card } from 'react-native-paper';
import { Divider } from 'react-native-paper';
// import { shortDateFormat } from '../../utils/format';
import { format, parse } from 'date-fns'
import MapView, { Marker } from 'react-native-maps';
import type { Region } from 'react-native-maps';
import { MaterialIcons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { colors } from '../../utils/colors';
import Lightbox from 'react-native-lightbox-v2';

export default function Job({ navigation, route }) {

  console.log("route: ", route)

  //const { job } = route.params;
  const jobParam = route.params.job;
  //const { userId } = route.params;
  const userId = route.params.userId;
  const { projectId } = route.params;
  const { jobId } = route.params;

  const [job, setJob] = useState();
  const [project, setProject] = useState();
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState([]);
  // const [numBedrooms, setNumBedrooms] = useState("N/A");
  // const [numBathrooms, setNumBathrooms] = useState("N/A");
  const [region, setRegion] = useState<Region | null>(null);

  console.log("job: ", JSON.stringify(jobParam))
  console.log("userId: ", userId)

  const renderItem = (open) => (
    <View style={styles.customItemBox}>
      <TouchableOpacity onPress={open} style={styles.customItemButton}>
        <Text style={styles.customItemText}>I'm a custom element</Text>
      </TouchableOpacity>
    </View>
  )

  useEffect(() => {
    // if (jobParam?.id === undefined) {
    console.log("no job passed in, loading from network.")
    setLoading(true);

    fetchJob(userId, projectId, jobId).then((data) => {
      console.log("data: ", data)
      data.bidDueDate = format(parse(data?.bidDueDate, 'yyyy/MM/dd', new Date()), 'MMMM dd, yyyy')
      data.jobStartWindowDate = format(parse(data?.jobStartWindowDate, 'yyyy/MM/dd', new Date()), 'MMMM dd, yyyy')
      data.jobEndWindowDate = format(parse(data?.jobEndWindowDate, 'yyyy/MM/dd', new Date()), 'MMMM dd, yyyy')

      // setNumBedrooms(data?.cleaningJob ? data?.cleaningJob.numBedrooms : "N/A")
      // setNumBathrooms(data?.cleaningJob ? data?.cleaningJob.numBathrooms : "N/A")

      setJob(data);
      setLoading(false);
      console.log("jobs: ", job)
    })
    // } else {
    //   console.log("job passed in")
    //   setJob(jobParam)
    // }

    fetchProject(userId, projectId).then((data) => {
      console.log("project: ", data)
      setProject(data)
    })

    fetchMediaForProject(userId, projectId).then((data) => {
      console.log("fetchMediaForProject data: ", data)
      setMedia(data)
      console.log("media: ", JSON.stringify(media))
    })

  }, []);

  // const mapRef = useRef<MapView>(null);

  // const loadingFallback = useMemo(() => {
  //   return (
  //     <View>
  //       <Text>Loading</Text>
  //     </View>
  //   );
  // }, []);

  return loading ? (
    <Loading />
  ) : (
    <View style={styles.container}>


      <View style={styles.header}>
        <Image style={styles.logo} source={require('../../../assets/housemint-logo.jpg')} />
        <Image style={styles.logoName} source={require('../../../assets/housemint-logo-name.jpg')} />
      </View>

      <FlatList style={{ paddingTop: 10 }}
        horizontal
        data={media}
        renderItem={({ item, index }) => {
          return (
            <View style={styles.media2Container} >
              <Lightbox renderItem={renderItem}>
                <Image
                  //style={styles.contain}
                  resizeMode="contain"
                  style={styles.image2}
                  source={{ uri: `${item.mediaLink}` }}
                />
              </Lightbox>
            </View>

          );
        }}
      />

      {/* <View style={styles.columnContainer}> */}
      <View>
        {/* <View style={[{ flex: 1 }]}> */}
        <View style={styles.property}>
          {/* <Text style={styles.propertyName}>Name:</Text> */}
          <Text style={[styles.propertyValue, { fontSize: 32 }]}>{job?.name}</Text>
        </View>


        {/* <Text style={styles.propertyName}>Type:</Text> */}


        {/* Cleaning Job Name */}
        {job?.type === 'cleaning' ? (
          <View style={styles.property}>
            <Text style={styles.propertyValue}>Cleaning</Text>
            <MaterialIcons name="cleaning-services" size={18} color={colors.textGrey} />
          </View>
        ) : null}

        {/* Flooring Job Name */}
        {job?.type === 'flooring' ? (
          <View style={styles.property}>
            <Text style={styles.propertyValue}>Flooring</Text>
            <MaterialCommunityIcons name="floor-plan" size={18} color={colors.textGrey} />
          </View>
        ) : null}

        {/* Painting Job Name */}
        {job?.type === 'painting' ? (
          <View style={styles.property}>
            <Text style={styles.propertyValue}>Painting</Text>
            <MaterialCommunityIcons name="format-paint" size={18} color={colors.textGrey} />
          </View>
        ) : null}

        <View style={styles.property}>
          <Text style={styles.propertyValue}>{project?.property?.address?.city}, {project?.property?.address?.state}</Text>
        </View>


        {/* </View> */}

        {/* <View style={styles.mapContainer}>
          <MapView
            ref={mapRef}
            provider="google"
            // style={{ flex: 1 }}
            style={{ width: 100, height: 100 }}
            onRegionChange={setRegion}
            loadingFallback={loadingFallback}
            googleMapsApiKey="AIzaSyAIyu5C9oAxsDVVz7_gvBJ19jT8j8V8Gkg"
          >
          </MapView>
        </View> */}
      </View>


      <Divider />

      <View style={styles.columnContainer}>

        <View style={[{ flex: 1 }, styles.rfPropertyVertical]}>
          {project?.property?.squareFeet ? (
            <Text style={styles.rfPropertyValue}>{project?.property?.squareFeet}</Text>
          ) :
            <Text style={styles.rfPropertyValue}>N/A</Text>
          }
          <Text style={styles.rfPropertyName}>Sq Ft</Text>
        </View>
        
        <View style={[{ flex: 1 }, styles.rfPropertyVertical]}>
          {project?.property?.numBedrooms ? (
            <Text style={styles.rfPropertyValue}>{project?.property?.numBedrooms}</Text>
          ) :
            <Text style={styles.rfPropertyValue}>N/A</Text>
          }
          <Text style={styles.rfPropertyName}>Bedrooms</Text>
        </View>
        
        <View style={[{ flex: 1 }, styles.rfPropertyVertical]}>
        {project?.property?.numBathrooms ? (
            <Text style={styles.rfPropertyValue}>{project?.property?.numBathrooms}</Text>
          ) :
            <Text style={styles.rfPropertyValue}>N/A</Text>
          }
          <Text style={styles.rfPropertyName}>Baths</Text>
        </View>

      </View>



      <Divider />

      <View style={styles.columnContainer}>

        <View style={[{ flex: 1 }, styles.rfPropertyVertical]}>
          <Text style={styles.rfPropertyValue}>{job?.budget}</Text>
          <Text style={styles.rfPropertyName}>Budget</Text>
        </View>

        <View style={[{ flex: 1 }, styles.rfPropertyVertical]}>
          <Text style={styles.rfPropertyValue}>{job?.bidDueDate}</Text>
          <Text style={styles.rfPropertyName}>Bid Due Date</Text>
        </View>

      </View>

      <View style={styles.columnContainer}>

        <View style={[{ flex: 1 }, styles.rfPropertyVertical]}>
          <Text style={styles.rfPropertyValue}>{job?.jobStartWindowDate}</Text>
          <Text style={styles.rfPropertyName}>Job Window Start Date</Text>
        </View>

        <View style={[{ flex: 1 }, styles.rfPropertyVertical]}>
          <Text style={styles.rfPropertyValue}>{job?.jobEndWindowDate}</Text>
          <Text style={styles.rfPropertyName}>Job Window End Date</Text>
        </View>
      </View>

      <View style={{ marginBottom: 10, marginTop: 10 }}>
        <Text style={styles.rfPropertyName}>Send your bid to 443-520-0247 or bids@housemint.io for the job</Text>
      </View>

      <Divider />

      <View style={[styles.propertyVertical, { marginBottom: 10, marginTop: 10 }]}>
        <Text style={styles.rfPropertyValue}>Scheduling</Text>
        <Text style={styles.rfPropertyName}>{job?.scheduling}</Text>
      </View>

      <View style={[styles.propertyVertical, { marginBottom: 10 }]}>
        <Text style={styles.rfPropertyValue}>Terms</Text>
        <Text style={styles.rfPropertyName}>{job?.terms}</Text>
      </View>

      <View style={[styles.propertyVertical, { marginBottom: 10 }]}>
        <Text style={styles.rfPropertyValue}>Property Access</Text>
        <Text style={styles.rfPropertyName}>{job?.propertyAccess}</Text>
      </View>

      <Divider />

      {/* Cleaning Job Details */}
      {job?.type === 'cleaning' ? (
        <JobDetailsCleaning job={job} styles={styles} />
      ) : null}

      {/* Flooring Job Details */}
      {job?.type === 'flooring' ? (
        <JobDetailsFlooring job={job} styles={styles} />
      ) : null}

      {/* Painting Job Details */}
      {job?.type === 'painting' ? (
        <JobDetailsPainting job={job} styles={styles} />
      ) : null}

      <Divider />

      {job?.additionalDetails !== null && job?.additionalDetails !== undefined && job?.additionalDetails.trim() !== "" ? (
        <View style={styles.propertyVertical}>
          <Text style={styles.rfPropertyValue}>Additional Details</Text>
          <Text style={styles.rfPropertyName}>{job?.additionalDetails}</Text>
        </View>
      ) : null}

      {/* <FlatList style={{ paddingVertical: 10 }}
        horizontal
        data={media}
        renderItem={({ item, index }) => {
          return (
            //  <Card style={styles.mediaCard} >
            <View style={styles.mediaContainer} >
              <Image
                source={{
                  uri: `${item.mediaLink}`,
                }}
                style={styles.image}
              />
            </View>
            // </Card>

          );
        }}
      /> */}


      {/* <Lightbox renderItem={renderItem}>
                <Image
                  //style={styles.contain}
                  resizeMode="contain"
                  style={styles.image2}
                  source={{ uri: `https://storage.googleapis.com/download/storage/v1/b/housemint-static/o/1%2F1%2Ftest?generation=1694722014143814&alt=media` }}
                />
              </Lightbox> */}



      {/* </View> */}
      <StatusBar style="auto" />
    </View>
  );
}
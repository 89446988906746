import React, { useEffect, useState } from 'react';
import { Text, View, ActivityIndicator, FlatList } from 'react-native';
//import { styles } from '../../screens/Job/styles';
import { Card } from 'react-native-paper';


const JobDetailsFlooring = ({ job, styles }) => {
  return (
    <View>

      <View>
        <Text style={styles.propertyName}>Rooms</Text>
        <FlatList style={{paddingVertical: 10}}
          horizontal
          data={job?.flooringJob.rooms}
          renderItem={({ item, index }) => {
            return (
              <Card style={styles.roomCard} >

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Name: </Text>
                  <Text style={styles.propertyValue}>{item?.name}</Text>
                </View>

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Type: </Text>
                  <Text style={styles.propertyValue}>{item?.type}</Text>
                </View>

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Square Foot: </Text>
                  <Text style={styles.propertyValue}>{item?.squareFoot}</Text>
                </View>

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Material Type: </Text>
                  <Text style={styles.propertyValue}>{item?.materialType}</Text>
                </View>

                <View style={styles.property}>
                  <Text style={styles.propertyName}>New Baseboard?: </Text>
                  <Text style={styles.propertyValue}>{item?.newBaseboard}</Text>
                </View>

                <View style={styles.property}>
                  <Text style={styles.propertyName}>Remove Existing Floor: </Text>
                  <Text style={styles.propertyValue}>{item?.removeExistingFloor}</Text>
                </View>

                <View style={styles.propertyVertical}>
                  <Text style={styles.propertyName}>Additional Comments: </Text>
                  <Text style={styles.propertyValue}>{item?.additionalComments}</Text>
                </View>

              </Card>

            );
          }}
        />
      </View>

    </View>
  );
};

export default JobDetailsFlooring;